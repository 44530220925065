/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

/* Customization */
@import "theme/common";
@import "theme/form";
@import "theme/modal";
@import "theme/popover";

@font-face {
  font-family: 'Gilroy-Bold';
  src: url('./assets/fonts/Gilroy-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy-Medium';
  src: url('./assets/fonts/Gilroy-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy-Regular';
  src: url('./assets/fonts/Gilroy-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

body {
  background: var(--ion-color-primary-contrast);
  color: var(--color-dark);
  font-family: 'Inter', 'sans-serif';

  .mbsc-ios.mbsc-button-flat {
    color: #00D082 !important;
    font-weight: 700;
  }

  .mbsc-ios.mbsc-scroller-pointer .mbsc-scroller-wheel-item.mbsc-selected {
    color: #00D082 !important;
  }
}

.page-with-fixed-header {
  .page-container {
    padding-top: 65px;
  }
}


.page-container {
  max-width: 768px;
  padding: 30px 20px;
  margin: auto;
  position: relative;
}

.content-max-width {
  max-width: 755px;
  margin: auto;
}

.fixed-header {
  z-index: 5;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  -webkit-overflow-scrolling: touch;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
}

.fixed-header {
  position: fixed;
}

.header {
  margin-top: 43px;
}

.app-page {
  padding-bottom: 70px;
  min-height: 100%;
  z-index: 9;
  overflow: scroll;
}

.back-icon {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 10px;
  color: #ffffff;
  z-index: 999;
  font-size: 18px;
}


input[type=text], input[type=number], input[type=password] {
  width: 100%;
  padding: 10px 15px;
  border-radius: 8px;
  border: 1px solid #C5C5C7;
  background-color: #f5f5f5;
  outline: none;

}

.no-spinner::-webkit-inner-spin-button,
.no-spinner::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.error-message span {
  display: block;
  text-align: center;
  color: red;
  margin: 10px 0px;
  width: 100%;
  font-size: 14px;
}


// loader css
.loader {
  width: 20px;
  height: 20px;
  border: 2px solid #000000;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  margin: auto;
}

.loaderonEdit {
  width: 20px;
  height: 20px;
  border: 2px solid #FFFFFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  margin: auto;
}


.savetemplate {
  width: 20px;
  height: 20px;
  border: 2px solid #000000;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  margin: auto;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.form-container {
  width: 100%;
  max-width: 768px;
  //padding: 0 20px 40px;
  padding: 0 20px 30px;
  margin: auto;
}


//scrollbar css
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: inherit;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #DDDDDD;
}

/* Handle on hover */


.tooltip-wrapper {
  //position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.tooltip-icon {
  cursor: pointer;
  font-size: 18px;
  color: #C5C5C7;
}

.ng-tooltip {
  position: absolute;
  max-width: 250px;
  font-size: 13px;
  text-align: left;
  color: #333333;
  padding: 10px;
  background: #d9d9d9;
  border-radius: 4px;
  z-index: 1000;
  opacity: 0;
}

.ng-tooltip:after {
  content: "";
  position: absolute;
  border-style: solid;
}

.ng-tooltip-top:after {
  top: 100%;
  right: 50%;
  margin-right: -36px;
  border-width: 5px;
  border-color: black transparent transparent transparent;
}

.ng-tooltip-show {
  opacity: 1;
  left: -30px;
}

.disabled {
  pointer-events: none;
  opacity: 0.6 !important;
}

.used-info .alert-wrapper {
  text-align: start !important;
}

.used-info .alert-message.sc-ion-alert-ios {
  max-height: initial !important;
}

.used-info .alert-message.sc-ion-alert-ios, .alert-input-group.sc-ion-alert-ios {
  text-align: start !important;
  font-size: 16px;
}


.used-info .alert-wrapper.sc-ion-alert-ios {
  width: 343px !important;
  max-width: initial !important;
}


.reload-info .alert-wrapper.sc-ion-alert-ios {
  width: 343px !important;
  max-width: initial !important;
}

.reload-info .alert-message.sc-ion-alert-ios, .alert-input-group.sc-ion-alert-ios {
  font-size: 16px;
}

ion-toast.toast-custom-class {
  color: #FFFFFF !important;
}
