body{
  background: var(--ion-color-primary-contrast);
  color: var(--color-dark);
  font-family: Inter, serif
}

ion-grid.container {
  --ion-grid-width: 768px;
  --ion-grid-width-xs: 100%;
}
