ion-modal.fb-connect, ion-modal.offer-preview {
  &.bottom {
    align-items: flex-end;
  }

  --height: auto;

  .ion-page {
    position: relative;
    display: block;
    contain: content;

    .inner-content {
      max-height: 80vh;
      overflow: auto;
      transition: height 200ms ease-in-out;
    }
  }
}
ion-modal.offer-preview {
  .modal-wrapper {
    width: 90%;
    max-width: 400px;
    box-shadow: 0 0 25px -1px rgba(0, 0, 0, 0.75);
  }
}
