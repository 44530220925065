.sm-ng-error {
  ion-input {
    //border: 1px solid red;
    border-radius: 13px;
    height: 56px;
  }

  .sm-ng-error-message {
    color: red;
    display: flex;
    justify-content: center;
    font-size: 14px;
  }
}

ion-input {
  --highlight-color: transparent !important;
  --border-color: transparent !important;
}


.form-group {
  margin-bottom: 20px;
  position: relative;
  display: flex;
  flex-direction: column;

  label {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.32px;
    margin-bottom: 10px;
  }

  input {
    outline: none;
  }

  input[type=text], input[type=number], input[type=password] {
    border-radius: 8px;
    border: 1px solid #C5C5C7;
    background-color: #F5F5F5;
    padding: 10px 15px;
    outline: none;
    margin-bottom: 10px;
  }

  textarea {
    resize: none;
    padding: 10px 15px;
    border-radius: 8px;
    border: 1px solid #C5C5C7;
    background-color: #F5F5F5;
    outline: none;
    margin-bottom: 10px;
    min-height: 235px;

  }

  &:last-child {
    margin-bottom: 10px;
  }

  .counter {
    position: absolute;
    right: 10px;
    bottom: -10px;
    font-size: 12px;
  }

  .warning {
    color: red;
  }

}

.form-group-sub-text {
  margin-bottom: 16px;
  padding: 2px;

  .left-adjustment {
    margin: 8px 0 0 20px;
  }

  .form-sub-text {
    font-size: 0.75rem;
    padding: 2px;
    color: rgba(60, 60, 67, 0.6);
  }
}

.date-input {
  font-size: 12px;
  font-weight: 400;
  line-height: 139.523%;
  letter-spacing: 0.12px;
  cursor: pointer;
  background: #F5F5F5;
  border: none;
  width: 60px;
  outline: none;
  padding: 8px 12px;
  border-radius: 4px;
  margin-left: 15px;
}


@media only screen and (max-width: 767px) {
  .form-group {
    textarea {
      resize: none;
      padding: 10px 15px;
      border-radius: 8px;
      border: 1px solid #C5C5C7;
      background-color: #F5F5F5;
      outline: none;
      margin-bottom: 10px;
      min-height: 240px;
    }
  }


}
